body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
@font-face {
  font-family: "BTReg";
  src: local("BTReg"), url(./assets/fonts/BTCurve_W_Rg.woff) format("truetype");
}

@font-face {
  font-family: "BTBold";
  src: local("BTBold"), url(./assets/fonts/BTCurve_W_Bd.woff) format("truetype");
}

@font-face {
  font-family: "BTRegItalic";
  src: local("BTRegItalic"), url(./assets/fonts/BTCurve_W_It.woff) format("truetype");
}
@font-face {
  font-family: "BTH1";
  src: local("BTH1"), url(./assets/fonts/BTCurve_W_Headline.woff) format("truetype");
}
@font-face {
  font-family: "BTH2";
  src: local("BTH2"), url(./assets/fonts/BTCurve_W_Headline.woff2) format("truetype");
}
@font-face {
  font-family: "BTRegCon";
  src: local("BTRegCon"), url(./assets/fonts/BTCurveCd_Rg.ttf) format("truetype");
}
@font-face {
  font-family: "BTBoldCon";
  src: local("BTBoldCon"), url(./assets/fonts/BTCurveCd_W_Bd.woff) format("truetype");
}
