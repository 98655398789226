.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

h1 {
  font-family: BTH1, serif;
  font-size: 2.5rem;
}

h2,
h3,
h4 {
  font-family: BTH1, serif;
}

p,
label,
option {
  font-family: BTReg, serif;
}

textarea {
  font-family: BTReg, serif;
  font-size: medium;
}

span {
  font-family: BTH1, serif;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* HOME PAGE */

.main-container {
  text-align: center;
}

.video-container {
  margin: auto;
  width: 50%;
  padding: 10px;
  min-width: 480px;
  align-items: center;
  text-align: center;
}

.button-container {
  margin: auto;
  width: 50%;
  padding-top: 20px;
  min-width: 480px;
  align-items: center;
  text-align: center;
}

.title-container {
  width: 100%;
  align-items: center;
  text-align: center;
}

.footnote-container {
  width: 100%;
  align-items: center;
  text-align: center;
}

/* LOGIN PAGE */

.form {
  margin: 0 auto;
  flex-direction: column;
  background: white;
  padding: 20px;
  margin-top: 30px;
}

.form .form__custom-button {
  margin-top: 50px;
}

.reason-textarea {
  width: 400px !important;
  height: 50px !important;
  resize: none;
  padding-left: 5px;
  border-radius: 5px;
  outline: none;
}

/* Views */

input[type="radio"] {
  position: relative;
  margin-right: 25px;
  color: red;
}

label {
  font-size: 1.1rem;
}

.redpulse {
  color: #eb0800;
  animation-name: redpulse;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

@keyframes redpulse {
  0% {
    color: #eb0800;
  }
  50% {
    color: #000000;
  }
  100% {
    color: #eb0800;
  }
}
