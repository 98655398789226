@font-face {
  font-family: "BT-Curve";
  src:
    local("BT-Curve"),
    url(./assets/fonts/BTCurve_W_Rg.woff) format("woff"),
    url(./assets/fonts/BTCurve_W_Rg.woff2) format("woff2");
  font-weight: normal
}

@font-face {
  font-family: "BT-Curve";
  src:
    local("BT-Curve"),
    url(./assets/fonts/BTCurve_W_Bd.woff) format("woff"),
    url(./assets/fonts/BTCurve_W_Bd.woff2) format("woff2");
  font-weight: bold;
}

@font-face {
  font-family: "BT-Curve";
  src:
    local("BT-Curve"),
    url(./assets/fonts/BTCurve_W_Headline.woff) format("woff"),
    url(./assets/fonts/BTCurve_W_Headline.woff2) format("woff2");
  font-weight: 700
}

* {
  font-family: BT-Curve, Arial, sans-serif;
}

body {
  margin: 0;
  background-color: #F5F5F5;
}